@tailwind utilities;
@tailwind base;

@layer base{
	h1, h2, h3, h4, h5, h6{
		font-weight: 700;
	}
	html{
		line-height: normal;
	}
	button{
		text-align: left;
	}
}

* {
    margin: 0;
    padding: 0;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-ExtraLight.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-Light.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-Thin.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-Regular.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-Medium.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-SemiBold.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-Bold.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-ExtraBold.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url("./fonts/outfit/Outfit-Black.ttf") format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}